// These helpers are calling this template's own server-side routes
// so, they are not directly calling Marketplace API or Integration API.
// You can find these api endpoints from 'server/api/...' directory

import Decimal from 'decimal.js';
import appSettings from '../config/settings';
import { transit, types as sdkTypes } from './sdkLoader';

export const apiBaseUrl = () => {
  const port = process.env.REACT_APP_DEV_API_SERVER_PORT;
  const useDevApiServer = process.env.NODE_ENV === 'development' && !!port;

  // In development, the dev API server is running in a different port
  if (useDevApiServer) {
    return `http://localhost:${port}`;
  }

  // Otherwise, use the same domain and port as the frontend
  return `${window.location.origin}`;
};

// Application type handlers for JS SDK.
//
// NOTE: keep in sync with `typeHandlers` in `server/api-util/sdk.js`
export const typeHandlers = [
  // Use Decimal type instead of SDK's BigDecimal.
  {
    type: sdkTypes.BigDecimal,
    customType: Decimal,
    writer: v => new sdkTypes.BigDecimal(v.toString()),
    reader: v => new Decimal(v.value),
  },
];

const serialize = data => {
  return transit.write(data, { typeHandlers, verbose: appSettings.sdk.transitVerbose });
};

const deserialize = str => {
  return transit.read(str, { typeHandlers });
};

const handleResponse = res => {
  const contentTypeHeader = res.headers.get('Content-Type');
  const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;

  if (res.status >= 400) {
    return res.json().then(data => {
      let e = new Error();
      e = Object.assign(e, data);

      throw e;
    });
  }
  if (contentType === 'application/transit+json') {
    return res.text().then(deserialize);
  } else if (contentType === 'application/json') {
    return res.json();
  }
  return res.text();
};

const get = (path, query) => {
  const queryString = !query ? '' : `?${new URLSearchParams(query).toString()}`;
  const url = `${apiBaseUrl()}${path}${queryString}`;
  const options = {
    method: 'GET',
    credentials: 'include',
  };

  return window.fetch(url, options).then(res => handleResponse(res));
};

const post = (path, body) => {
  const url = `${apiBaseUrl()}${path}`;
  const options = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/transit+json',
    },
    body: serialize(body),
  };
  return window.fetch(url, options).then(res => handleResponse(res));
};

const put = (url, { contentType, body }) => {
  const options = {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': contentType,
    },
    body,
  };
  return window.fetch(url, options).then(res => handleResponse(res));
};

const deleteMethod = url => {
  const options = {
    method: 'DELETE',
  };
  return window.fetch(url, options).then(res => handleResponse(res));
};

// Fetch transaction line items from the local API endpoint.
//
// See `server/api/transaction-line-items.js` to see what data should
// be sent in the body.
export const transactionLineItems = body => {
  return post('/api/transaction-line-items', body);
};

// Initiate a privileged transaction.
//
// With privileged transitions, the transactions need to be created
// from the backend. This endpoint enables sending the order data to
// the local backend, and passing that to the Marketplace API.
//
// See `server/api/initiate-privileged.js` to see what data should be
// sent in the body.
export const initiatePrivileged = body => {
  return post('/api/initiate-privileged', body);
};

// Transition a transaction with a privileged transition.
//
// This is similar to the `initiatePrivileged` above. It will use the
// backend for the transition. The backend endpoint will add the
// payment line items to the transition params.
//
// See `server/api/transition-privileged.js` to see what data should
// be sent in the body.
export const transitionPrivileged = body => {
  return post('/api/transition-privileged', body);
};

// Create user with identity provider (e.g. Facebook or Google)
//
// If loginWithIdp api call fails and user can't authenticate to Marketplace API with idp
// we will show option to create a new user with idp.
// For that user needs to confirm data fetched from the idp.
// After the confirmation, this endpoint is called to create a new user with confirmed data.
//
// See `server/api/auth/createUserWithIdp.js` to see what data should
// be sent in the body.
export const createUserWithIdp = body => {
  return post('/api/auth/create-user-with-idp', body);
};

// Listings
export const contactListing = ({ listingId, content }) => {
  return post(`/api/listings/contact/${listingId}`, { content });
};

export const getContactInfo = listingUuid => get(`/api/listings/${listingUuid}/contact-info`);

// Users
export const createUser = body => post('/api/users', body);

export const updateUserProfile = body => {
  return post('/api/users/update-profile', body);
};

// S3
export const generatePresignedURL = ({ action, filename, filetype }) => {
  return get('/api/users/generate-presigned-url', { action, filename, filetype });
};

export const uploadFile = ({ contentType, file, url }) => {
  return put(url, { contentType, body: file });
};

export const deleteFile = url => {
  return deleteMethod(url);
};

// Subscriptions
export const subscribeSubscription = body => {
  return post('/api/subscriptions/subscribe', body);
};

export const cancelSubscription = () => {
  return post('/api/subscriptions/cancel', {});
};

export const fetchSubscriptionPacks = () => {
  return get('/api/subscriptions/packs');
};

export const fetchSubscriptionPack = ({ id }) => {
  return get(`/api/subscriptions/pack/${id}`);
};

export const fetchSubscription = () => {
  return get('/api/subscriptions');
};

export const resubscribeSubscription = body => {
  return post('/api/subscriptions/resubscribe', body);
};

export const fetchCurrentCredits = () => {
  return get('/api/credits');
};

// Transaction
export const getListingContactInfo = transactionUuid =>
  get(`/api/transactions/${transactionUuid}/listing/contact-info`);

export const deleteUserAccount = body => {
  return post('/api/delete-account', body);
};
