import pick from 'lodash/pick';
import { login } from '../../ducks/auth.duck';
import { createUser } from '../../util/api';
import { ROLE_CUSTOMER } from '../../util/data';
import { storableError } from '../../util/errors';
import { generateUserPassword } from '../../util/user';

export const SET_INITIAL_VALUES = 'app/LeadLandingPage/SET_INITIAL_VALUES';

export const CREATE_CUSTOMER_AND_LISTING_REQUEST =
  'app/LeadLandingPage/CREATE_CUSTOMER_AND_LISTING_REQUEST';
export const CREATE_CUSTOMER_AND_LISTING_SUCCESS =
  'app/LeadLandingPage/CREATE_CUSTOMER_AND_LISTING_SUCCESS';
export const CREATE_CUSTOMER_AND_LISTING_ERROR =
  'app/LeadLandingPage/CREATE_CUSTOMER_AND_LISTING_ERROR';

export const CREATE_CUSTOMER_REQUEST = 'app/LeadLandingPage/CREATE_CUSTOMER_REQUEST';
export const CREATE_CUSTOMER_SUCCESS = 'app/LeadLandingPage/CREATE_CUSTOMER_SUCCESS';
export const CREATE_CUSTOMER_ERROR = 'app/LeadLandingPage/CREATE_CUSTOMER_ERROR';

export const CREATE_LISTING_REQUEST = 'app/LeadLandingPage/CREATE_LISTING_REQUEST';
export const CREATE_LISTING_SUCCESS = 'app/LeadLandingPage/CREATE_LISTING_SUCCESS';
export const CREATE_LISTING_ERROR = 'app/LeadLandingPage/CREATE_LISTING_ERROR';

const initialState = {
  createCustomerAndListingInProgress: false,

  createCustomerInProgress: false,
  createCustomerError: null,

  createListingInProgress: false,
  createListingError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case CREATE_CUSTOMER_AND_LISTING_REQUEST:
      return {
        ...state,
        createCustomerAndListingInProgress: true,
      };
    case CREATE_CUSTOMER_AND_LISTING_SUCCESS:
    case CREATE_CUSTOMER_AND_LISTING_ERROR:
      return {
        ...state,
        createCustomerAndListingInProgress: false,
      };

    case CREATE_CUSTOMER_REQUEST:
      return {
        ...state,
        createCustomerInProgress: true,
        createCustomerError: null,
      };
    case CREATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        createUserInProgress: false,
      };
    case CREATE_CUSTOMER_ERROR:
      return {
        ...state,
        createCustomerInProgress: false,
        createCustomerError: payload,
      };

    case CREATE_LISTING_REQUEST:
      return {
        ...state,
        createListingInProgress: true,
        createListingError: null,
      };
    case CREATE_LISTING_SUCCESS:
      return {
        ...state,
        createListingInProgress: false,
      };
    case CREATE_LISTING_ERROR:
      return {
        ...state,
        createListingInProgress: false,
        createListingError: payload,
      };

    default:
      return state;
  }
}

export const createCustomerAndListingRequest = () => ({
  type: CREATE_CUSTOMER_AND_LISTING_REQUEST,
});
export const createCustomerAndListingSuccess = () => ({
  type: CREATE_CUSTOMER_AND_LISTING_SUCCESS,
});
export const createCustomerAndListingError = () => ({
  type: CREATE_CUSTOMER_AND_LISTING_ERROR,
});

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const createCustomerRequest = () => ({ type: CREATE_CUSTOMER_REQUEST });
export const createCustomerSuccess = () => ({ type: CREATE_CUSTOMER_SUCCESS });
export const createCustomerError = error => ({ type: CREATE_CUSTOMER_ERROR, payload: error });

export const createListingRequest = () => ({ type: CREATE_LISTING_REQUEST });
export const createListingSuccess = () => ({ type: CREATE_LISTING_SUCCESS });
export const createListingError = error => ({ type: CREATE_LISTING_ERROR, payload: error });

export const requestCreateListing = listingData => (dispatch, getState, sdk) => {
  dispatch(createListingRequest());
  const currentUser = getState().user.currentUser;

  const { location, phoneNumber, ...publicData } = listingData;
  const { address, origin, fragment } = location?.selectedPlace ?? {};
  const geolocationMaybe = origin ? { geolocation: origin } : {};

  return sdk.ownListings
    .create(
      {
        title: currentUser?.attributes?.profile?.displayName ?? '',
        ...geolocationMaybe,
        publicData: {
          ...publicData,
          location: {
            address,
            fragment,
          },
          listingType: 'job',
          transactionProcessAlias: 'default-purchase/release-1',
          unitType: 'item',
        },
        privateData: {
          phoneNumber,
        },
        availabilityPlan: {
          type: 'availability-plan/time',
          timezone: 'Etc/UTC',
          entries: [
            { dayOfWeek: 'mon', startTime: '00:00', endTime: '00:00', seats: 0 },
            { dayOfWeek: 'tue', startTime: '00:00', endTime: '00:00', seats: 0 },
            { dayOfWeek: 'wed', startTime: '00:00', endTime: '00:00', seats: 0 },
            { dayOfWeek: 'thu', startTime: '00:00', endTime: '00:00', seats: 0 },
            { dayOfWeek: 'fri', startTime: '00:00', endTime: '00:00', seats: 0 },
            { dayOfWeek: 'sat', startTime: '00:00', endTime: '00:00', seats: 0 },
            { dayOfWeek: 'sun', startTime: '00:00', endTime: '00:00', seats: 0 },
          ],
        },
      },
      {
        expand: true,
        ['fields.listing']: ['title'],
      }
    )
    .then(response => {
      dispatch(createListingSuccess());
      return response.data.data;
    })
    .catch(e => {
      dispatch(createListingError(storableError(e)));
      throw e;
    });
};

export const requestCreateCustomer = userInfo => (dispatch, getState, sdk) => {
  dispatch(createCustomerRequest());
  const { email, password, phoneNumber, firstName, lastName } = userInfo;

  return createUser({
    email,
    phoneNumber,
    password,
    firstName,
    lastName,
    role: ROLE_CUSTOMER,
    sendPasswordEmail: true,
  })
    .then(() => dispatch(createCustomerSuccess()))
    .catch(e => {
      dispatch(createCustomerError(storableError(e)));
      throw e;
    });
};

export const requestCreateCustomerAndListing = (userInfo, listingData) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(createCustomerAndListingRequest());

  const { email } = userInfo;
  const password = generateUserPassword();
  return dispatch(requestCreateCustomer({ ...userInfo, password }))
    .then(() => dispatch(login(email, password)))
    .then(() => dispatch(requestCreateListing(listingData)))
    .then(listing => {
      dispatch(createCustomerAndListingSuccess());
      return listing;
    })
    .catch(e => {
      dispatch(createCustomerAndListingError());
      throw e;
    });
};

export const loadData = () => (dispatch, getState) => {
  dispatch(setInitialValues());
  return Promise.resolve();
};
