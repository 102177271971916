import * as React from 'react';

const IconInjury = props => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" width={128} height={128} {...props}>
    <path
      fill="#282D33"
      d="M71.803 69.827c-11.476 0-20.81-9.301-20.81-20.732V33.95c0-11.43 9.334-20.728 20.81-20.728 11.473 0 20.809 9.298 20.809 20.728v15.145c-.001 11.431-9.337 20.732-20.809 20.732zm0-52.604c-9.27 0-16.811 7.504-16.811 16.728v15.145c0 9.227 7.541 16.732 16.811 16.732 9.268 0 16.809-7.506 16.809-16.732V33.95c-.001-9.223-7.542-16.727-16.809-16.727z"
    />
    <path
      fill="#282D33"
      d="M115.42 127.995h-4V66.628c0-3.514-2.871-6.372-6.4-6.372H88.193v-4h16.826c5.734 0 10.4 4.652 10.4 10.372v61.367zm-83.235 0h-4V66.628c0-5.72 4.667-10.372 10.402-10.372h16.819v4H38.587c-3.531 0-6.402 2.858-6.402 6.372v61.367z"
    />
    <path
      fill="#282D33"
      d="M82.406 128H27.983v-4h54.423c4.852 0 8.799-3.928 8.799-8.758s-3.947-8.759-8.799-8.759H70.801v-4h11.605c7.059 0 12.799 5.723 12.799 12.759 0 7.035-5.74 12.758-12.799 12.758z"
    />
    <path
      fill="#282D33"
      d="M41.589 58.256h4V126h-4zm48.015 28.69h4v22.114h-4zM60.696 62.408l20.805 62.925-3.797 1.256-20.805-62.926zm-1.605-42.485 32.812 21.514-2.194 3.345-32.811-21.515zM29.367 39.249l5.4 9.317-3.461 2.006-5.4-9.317zM17.504 49.9l9.354 5.378-1.994 3.468-9.354-5.379zM12.58 65.031h10.799v4H12.58zM99.407 25.374h10.811v4H99.407zm5.48-15.018 1.994 3.466-9.352 5.38-1.994-3.466zM92.726.004l3.46 2.006-5.396 9.315-3.461-2.005z"
    />
  </svg>
);
export default IconInjury;
