import { object, shape, string } from 'prop-types';
import React from 'react';
import IconEnvelope from '../IconEnvelope/IconEnvelope';
import IconTelephone from '../IconTelephone/IconTelephone';

import css from './ContactInfo.module.css';

const ContactInfo = props => {
  const { contactInfo, fetchContactInfoError } = props;
  if (!contactInfo || fetchContactInfoError) return null;

  const { email, phoneNumber } = contactInfo;
  const emailLine = email ? (
    <div className={css.container}>
      <IconEnvelope className={css.icon} />
      <span>{email}</span>
    </div>
  ) : null;
  const phoneLine = phoneNumber ? (
    <div className={css.container}>
      <IconTelephone className={css.icon} />
      <span>{phoneNumber}</span>
    </div>
  ) : null;

  return (
    <>
      {phoneLine}
      {emailLine}
    </>
  );
};

ContactInfo.propTypes = {
  contactInfo: shape({
    email: string,
    phoneNumber: string,
  }),
  fetchContactInfoError: object,
};

export default ContactInfo;
