const PASSWORD_MIN_LENGTH = 8;
const PASSWORD_CHARACTERS =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+-=[]{}|;:,.<>?';

export const generateUserPassword = (length = PASSWORD_MIN_LENGTH) => {
  if (typeof window === 'undefined') throw new Error('window is not defined');

  return Array.from(window.crypto.getRandomValues(new Uint8Array(length)))
    .map(value => PASSWORD_CHARACTERS[value % PASSWORD_CHARACTERS.length])
    .join('');
};
