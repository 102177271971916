import * as React from "react"
const IconEmployment = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={800}
    height={800}
    className="icon"
    viewBox="0 0 1024 1024"
    {...props}
  >
    <path
      fill="#0F1F3C"
      d="M182.52 146.2h585.14v256h73.15V73.06H109.38v877.71h256v-73.14H182.52z"
    />
    <path
      fill="#0F1F3C"
      d="M255.67 219.34h438.86v73.14H255.67zm0 146.29h365.71v73.14H255.67zm0 146.28H475.1v73.14H255.67zm519.55-53.67L439.04 794.42l-.52 154.64 155.68.52L930.38 613.4 775.22 458.24zm51.72 155.16-25.43 25.43-51.73-51.72 25.44-25.44 51.72 51.73zm-77.14 77.15L620.58 819.77l-51.72-51.72 129.22-129.22 51.72 51.72zM511.91 876.16l.17-51.34 5.06-5.06 51.72 51.72-4.85 4.85-52.1-.17z"
    />
  </svg>
)
export default IconEmployment
