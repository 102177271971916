import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import css from './IconCircleTick.module.css';

const IconCircleTick = props => {
  const { className } = props;
  return (
    <svg
      className={classNames(css.root, className)}
      height="64px"
      width="64px"
      viewBox="0 0 310.277 310.277"
    >
      <g strokeWidth="0" />
      <g strokeLinecap="round" strokeLinejoin="round" />
      <g>
        <g>
          <path d="M155.139,0C69.598,0,0,69.598,0,155.139c0,85.547,69.598,155.139,155.139,155.139 c85.547,0,155.139-69.592,155.139-155.139C310.277,69.598,240.686,0,155.139,0z M144.177,196.567L90.571,142.96l8.437-8.437 l45.169,45.169l81.34-81.34l8.437,8.437L144.177,196.567z" />{' '}
        </g>
      </g>
    </svg>
  );
};

IconCircleTick.defaultProps = { className: null, checkedClassName: null, boxClassName: null };
IconCircleTick.propTypes = { className: string, checkedClassName: string, boxClassName: string };

export default IconCircleTick;
