import {
  CURRENT_PLAN_BADGE,
  RECOMMENDED_BADGE,
  SUBSCRIPTION_STATUS_ACTIVE,
  WERE_ON_PLAN_BADGE,
} from './types';
import { createResourceLocatorString, findRouteByRouteName } from './routes';
import { getUserRoles } from './data';
import { validRolesToUseSubscription } from '../config/configSubscription';
import Decimal from 'decimal.js';

export const getCurrentUserSubscription = currentUser =>
  currentUser?.attributes.profile.metadata?.subscription;

export const isAllowToAccessSubscriptionFeature = currentUser => {
  const userRoles = getUserRoles(currentUser);
  return validRolesToUseSubscription.every(role => userRoles.includes(role));
};

export const checkDuplicatedSubscription = (currentSubscription, packId) =>
  !currentSubscription ||
  currentSubscription.productId !== packId ||
  currentSubscription.status !== SUBSCRIPTION_STATUS_ACTIVE;

/**
 * Calculate tax from pack price and tax rate.
 * @param {Number} packPrice
 * @param {Number} taxRate
 * @returns {Decimal}
 */
export const calculateTax = (packPrice, taxRate) =>
  packPrice && taxRate
    ? new Decimal(packPrice)
        .mul(taxRate)
        .div(100)
    : new Decimal(0);

/**
 * Calculate total price after applying tax rates.
 * @param {Number} packPrice
 * @param {Array<Number>} taxRates
 * @returns {Number}
 */
export const calculateTotal = (packPrice, taxRates) => {
  const tax = taxRates.reduce(
    (total, taxRate) => total.plus(calculateTax(packPrice, taxRate)),
    new Decimal(0)
  );
  const totalPrice = tax.plus(packPrice);
  return totalPrice.isInteger() ? totalPrice.toNumber() : totalPrice.toFixed(2);
};

/**
 * Check if the user is eligible to subscribe to a pack.
 * The user is eligible to subscribe if:
 * - The user has no subscription and the pack is the default pack
 * - The user has a subscription but the pack is different from the current subscription
 * - The user has a subscription but the subscription is expired
 * @param {currentUser} currentUser
 * @param {string} packId
 * @param {string} defaultPack
 * @returns {boolean}
 */
export const isEligibleToSubscribe = (currentUser, packId, defaultPack) => {
  const currentSubscription = getCurrentUserSubscription(currentUser);
  return (
    (!currentSubscription && packId === defaultPack) ||
    (currentSubscription &&
      (currentSubscription.productId !== packId ||
        currentSubscription.status !== SUBSCRIPTION_STATUS_ACTIVE))
  );
};

/**
 * Handle order submit from OrderPanel.
 * @param {Object} parameters all the info needed to redirect user to CheckoutPage.
 */
export const onSubscribeSubscription = parameters => values => {
  const {
    history,
    currentUser,
    callSetInitialValues,
    onInitializeCardPaymentData,
    routes,
  } = parameters;
  const { subscriptionPackId } = values;
  const initialValues = {
    orderData: {
      subscriptionPackId,
    },
  };
  const saveToSessionStorage = !currentUser;

  // Customize checkout page state with current listing and selected orderData
  const { setInitialValues } = findRouteByRouteName('CheckoutSubscriptionPage', routes);

  callSetInitialValues(setInitialValues, initialValues, saveToSessionStorage);

  // Clear previous Stripe errors from store if there is any
  onInitializeCardPaymentData();

  // Redirect to CheckoutPage
  history.push(createResourceLocatorString('CheckoutSubscriptionPage', routes, {}, {}));
};

export const getPackageBadge = ({ isRecommendedPack, existingSubscription, planId }) => {
  if (isRecommendedPack) return RECOMMENDED_BADGE;
  if (existingSubscription.productId !== planId) return null;
  if (existingSubscription.status !== SUBSCRIPTION_STATUS_ACTIVE) return WERE_ON_PLAN_BADGE;
  return CURRENT_PLAN_BADGE;
};
