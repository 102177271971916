import React from 'react';
import Button from '../Button/Button';
import { bool, func, number, string } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { customFormatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
const { Money } = sdkTypes;
import { propTypes } from '../../util/types';
import css from './SubscriptionPackage.module.css';

export default function SubscriptionPackage({
  price,
  credits,
  interval,
  currency,
  intl,
  badge,
  onSubscribe,
  className,
  isEligible,
}) {
  const priceAsMoney = new Money(price, currency.toUpperCase());
  const title = (
    <FormattedMessage
      id="SubscriptionPackage.credits"
      values={{
        credits,
      }}
    />
  );
  const formattedPrice = customFormatMoney(intl, priceAsMoney);
  
  const priceWithStyle = (
    <b className={css.price}>
      {
        <FormattedMessage
          id="SubscriptionPackage.price"
          values={{
            price: formattedPrice,
          }}
        />
      }
    </b>
  );

  const priceWithInterval = (
    <FormattedMessage
      id="SubscriptionPackage.pricePerInterval"
      values={{
        price: priceWithStyle,
        interval,
      }}
    />
  );
  const badgeLabel = badge && <FormattedMessage id={`SubscriptionPackage.badges.${badge}`} />;
  return (
    <div className={classNames(css.root, className)}>
      <h3 className={css.title}>{title}</h3>
      <p className={css.description}>{priceWithInterval}</p>
      <p className={classNames(css.badge, css[badge])}>{badgeLabel}</p>
      <Button className={css.button} onClick={onSubscribe} disabled={!isEligible}>
        {intl.formatMessage({ id: 'SubscriptionPackage.subscribeButton' })}
      </Button>
    </div>
  );
}

SubscriptionPackage.propTypes = {
  price: number.isRequired,
  credits: string.isRequired,
  interval: string.isRequired,
  currency: string.isRequired,
  intl: intlShape.isRequired,
  badge: propTypes.packageBadgeType,
  onSubscribe: func.isRequired,
  className: string,
  isEligible: bool,
};

SubscriptionPackage.defaultProps = {
  className: null,
  badge: null,
  isEligible: false,
};
