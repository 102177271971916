import React from 'react';
import classNames from 'classnames';
import { bool, string } from 'prop-types';
import Slider from 'react-slick';

import leftArrow from './leftArrow.png';
import rightArrow from './rightArrow.png';

import css from './Carousel.module.css';

import '../../styles/slick/slick.css';
import '../../styles/slick/slick-theme.css';

const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
  <button
    {...props}
    className={css.button}
    aria-hidden="true"
    aria-disabled={currentSlide === 0 ? true : false}
    type="button"
  >
    <img alt="Left arrow button" src={leftArrow} width="30px" />
  </button>
);

const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
  <button
    {...props}
    className={css.button}
    aria-hidden="true"
    aria-disabled={currentSlide === slideCount ? true : false}
    type="button"
  >
    <img alt="Right arrow button" src={rightArrow} width="30px" />
  </button>
);

const Carousel = props => {
  const { className, rootClassName, children, pagination, ...componentProps } = props;

  const classes = rootClassName || classNames(css.root, className);

  return (
    <div className={classes}>
      <Slider
        className={classes}
        arrows={pagination}
        nextArrow={pagination ? <SlickArrowRight /> : null}
        prevArrow={pagination ? <SlickArrowLeft /> : null}
        {...componentProps}
      >
        {children}
      </Slider>
    </div>
  );
};

Carousel.defaultProps = {
  className: '',
  rootClassName: '',
  pagination: true,
};

Carousel.propTypes = {
  className: string,
  rootClassName: string,
  pagination: bool,
};

export default Carousel;
