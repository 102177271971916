import { storableError } from '../util/errors';
import * as log from '../util/log';
import {
  cancelSubscription,
  subscribeSubscription,
  fetchSubscription,
  resubscribeSubscription,
} from '../util/api';
import { denormalisedResponseEntities } from '../util/data';
import { fetchCurrentUser } from './user.duck';

// ================ Action types ================ //

export const FETCH_SUBSCRIPTION_REQUEST = 'app/subscription/FETCH_SUBSCRIPTION_REQUEST';
export const FETCH_SUBSCRIPTION_SUCCESS = 'app/subscription/FETCH_SUBSCRIPTION_SUCCESS';
export const FETCH_SUBSCRIPTION_ERROR = 'app/subscription/FETCH_SUBSCRIPTION_ERROR';

export const SUBSCRIBE_STRIPE_SUBSCRIPTION_REQUEST =
  'app/subscription/SUBSCRIBE_STRIPE_SUBSCRIPTION_REQUEST';
export const SUBSCRIBE_STRIPE_SUBSCRIPTION_SUCCESS =
  'app/subscription/SUBSCRIBE_STRIPE_SUBSCRIPTION_SUCCESS';
export const SUBSCRIBE_STRIPE_SUBSCRIPTION_ERROR =
  'app/subscription/SUBSCRIBE_STRIPE_SUBSCRIPTION_ERROR';

export const CANCEL_STRIPE_SUBSCRIPTION_REQUEST =
  'app/subscription/CANCEL_STRIPE_SUBSCRIPTION_REQUEST';
export const CANCEL_STRIPE_SUBSCRIPTION_SUCCESS =
  'app/subscription/CANCEL_STRIPE_SUBSCRIPTION_SUCCESS';
export const CANCEL_STRIPE_SUBSCRIPTION_ERROR = 'app/subscription/CANCEL_STRIPE_SUBSCRIPTION_ERROR';

export const RESUBSCRIBE_STRIPE_SUBSCRIPTION_REQUEST =
  'app/subscription/RESUBSCRIBE_STRIPE_SUBSCRIPTION_REQUEST';
export const RESUBSCRIBE_STRIPE_SUBSCRIPTION_SUCCESS =
  'app/subscription/RESUBSCRIBE_STRIPE_SUBSCRIPTION_SUCCESS';
export const RESUBSCRIBE_STRIPE_SUBSCRIPTION_ERROR =
  'app/subscription/RESUBSCRIBE_STRIPE_SUBSCRIPTION_ERROR';

// ================ Reducer ================ //

const initialState = {
  currentSubscription: null,
  fetchSubscriptionInProgress: false,
  fetchSubscriptionError: null,
  subscribeSubscriptionInProgress: false,
  subscribeSubscriptionError: null,
  cancelSubscriptionInProgress: false,
  cancelSubscriptionError: null,
  resubscribeSubscriptionInProgress: false,
  resubscribeSubscriptionError: null,
};

export default function subscriptionReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_SUBSCRIPTION_REQUEST:
      return { ...state, fetchSubscriptionInProgress: true, fetchSubscriptionError: null };
    case FETCH_SUBSCRIPTION_SUCCESS:
      return { ...state, fetchSubscriptionInProgress: false, currentSubscription: payload };
    case FETCH_SUBSCRIPTION_ERROR:
      return { ...state, fetchSubscriptionInProgress: false, fetchSubscriptionError: payload };

    case SUBSCRIBE_STRIPE_SUBSCRIPTION_REQUEST:
      return { ...state, subscribeSubscriptionInProgress: true, subscribeSubscriptionError: null };
    case SUBSCRIBE_STRIPE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscribeSubscriptionInProgress: false,
        subscribeSubscriptionError: null,
      };
    case SUBSCRIBE_STRIPE_SUBSCRIPTION_ERROR:
      return { ...state, subscribeSubscriptionInProgress: false, subscribeSubscriptionError: null };

    case CANCEL_STRIPE_SUBSCRIPTION_REQUEST:
      return { ...state, cancelSubscriptionInProgress: true, cancelSubscriptionError: null };
    case CANCEL_STRIPE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        cancelSubscriptionInProgress: false,
        cancelSubscriptionError: null,
      };
    case CANCEL_STRIPE_SUBSCRIPTION_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return {
        ...state,
        resubscribeSubscriptionInProgress: false,
        resubscribeSubscriptionError: null,
      };

    case RESUBSCRIBE_STRIPE_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        resubscribeSubscriptionInProgress: true,
        resubscribeSubscriptionError: null,
      };
    case RESUBSCRIBE_STRIPE_SUBSCRIPTION_SUCCESS:
    case RESUBSCRIBE_STRIPE_SUBSCRIPTION_ERROR:
      return {
        ...state,
        resubscribeSubscriptionInProgress: false,
        resubscribeSubscriptionError: null,
      };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const fetchStripeSubscriptionRequest = () => ({ type: FETCH_SUBSCRIPTION_REQUEST });
export const fetchStripeSubscriptionSuccess = currentSubscription => ({
  type: FETCH_SUBSCRIPTION_SUCCESS,
  payload: currentSubscription,
});
export const fetchStripeSubscriptionError = e => ({
  type: FETCH_SUBSCRIPTION_ERROR,
  error: true,
  payload: e,
});

export const subscribeStripeSubscriptionRequest = () => ({
  type: SUBSCRIBE_STRIPE_SUBSCRIPTION_REQUEST,
});
export const subscribeStripeSubscriptionSuccess = () => ({
  type: SUBSCRIBE_STRIPE_SUBSCRIPTION_SUCCESS,
});
export const subscribeStripeSubscriptionError = e => ({
  type: SUBSCRIBE_STRIPE_SUBSCRIPTION_ERROR,
  error: true,
  payload: e,
});

export const cancelStripeSubscriptionRequest = () => ({ type: CANCEL_STRIPE_SUBSCRIPTION_REQUEST });
export const cancelStripeSubscriptionSuccess = () => ({ type: CANCEL_STRIPE_SUBSCRIPTION_SUCCESS });
export const cancelStripeSubscriptionError = e => ({
  type: CANCEL_STRIPE_SUBSCRIPTION_ERROR,
  error: true,
  payload: e,
});

export const resubscribeStripeSubscriptionRequest = () => ({
  type: RESUBSCRIBE_STRIPE_SUBSCRIPTION_REQUEST,
});
export const resubscribeStripeSubscriptionSuccess = () => ({
  type: RESUBSCRIBE_STRIPE_SUBSCRIPTION_SUCCESS,
});
export const resubscribeStripeSubscriptionError = e => ({
  type: RESUBSCRIBE_STRIPE_SUBSCRIPTION_ERROR,
  error: true,
  payload: e,
});

/* ================ Thunks ================ */

export const fetchStripeSubscription = () => (dispatch, getState, sdk) => {
  dispatch(fetchStripeSubscriptionRequest());

  return fetchSubscription()
    .then(response => {
      const subscriptionRes = response && denormalisedResponseEntities(response);
      dispatch(fetchStripeSubscriptionSuccess(subscriptionRes?.[0]));
    })
    .catch(e => {
      dispatch(fetchStripeSubscriptionError(storableError(e)));
      log.error(e, 'fetch-stripe-subscription-failed');
      throw e;
    });
};

export const subscribeStripeSubscription = () => (dispatch, getState, sdk) => {
  dispatch(subscribeStripeSubscriptionRequest());
  return subscribeSubscription()
    .then(result => {
      dispatch(subscribeStripeSubscriptionSuccess());
      const subscriptionRes = denormalisedResponseEntities(result);
      return subscriptionRes?.[0];
    })
    .catch(e => {
      dispatch(subscribeStripeSubscriptionError(storableError(e)));
      log.error(e, 'subscribe-stripe-subscription-failed');
      throw e;
    });
};

export const cancelStripeSubscription = () => (dispatch, getState, sdk) => {
  dispatch(cancelStripeSubscriptionRequest());
  return cancelSubscription()
    .then(() => {
      dispatch(cancelStripeSubscriptionSuccess());
    })
    .then(() => {
      dispatch(fetchCurrentUser());
    })
    .catch(e => {
      dispatch(cancelStripeSubscriptionError(storableError(e)));
      log.error(e, 'cancel-stripe-subscription-failed');
      throw e;
    });
};

export const resubscribeStripeSubscription = params => (dispatch, getState, sdk) => {
  dispatch(resubscribeStripeSubscriptionRequest());

  return resubscribeSubscription(params)
    .then(result => {
      dispatch(resubscribeStripeSubscriptionSuccess());
      const subscriptionRes = denormalisedResponseEntities(result);
      return subscriptionRes?.[0];
    })
    .catch(e => {
      dispatch(resubscribeStripeSubscriptionError(storableError(e)));
      log.error(e, 'resubscribe-stripe-subscription-failed');
      throw e;
    });
};
