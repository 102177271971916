import React from 'react';
import PropTypes from 'prop-types';

const IconAnchor = props => {
  const { className } = props;

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      viewBox="0 0 16 16"
    >
      <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
    </svg>
  );
};

const { string } = PropTypes;

IconAnchor.defaultProps = {
  className: null,
};

IconAnchor.propTypes = {
  className: string,
};

export default IconAnchor;
