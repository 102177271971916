/////////////////////////////////////////////////////////
// Configurations related to listing.                  //
// Main configuration here is the extended data config //
/////////////////////////////////////////////////////////

// NOTE: if you want to change the structure of the data,
// you should also check src/util/configHelpers.js
// some validation is added there.

/**
 * Configuration options for listing fields (custom extended data fields):
 * - key:                           Unique key for the extended data field.
 * - scope (optional):              Scope of the extended data can be either 'public' or 'private'.
 *                                  Default value: 'public'.
 *                                  Note: listing doesn't support 'protected' scope atm.
 * - includeForListingTypes:        An array of listing types, for which the extended
 *   (optional)                     data is relevant and should be added.
 * - schemaType (optional):         Schema for this extended data field.
 *                                  This is relevant when rendering components and querying listings.
 *                                  Possible values: 'enum', 'multi-enum', 'text', 'long', 'boolean'.
 * - enumOptions (optional):        Options shown for 'enum' and 'multi-enum' extended data.
 *                                  These are used to render options for inputs and filters on
 *                                  EditListingPage, ListingPage, and SearchPage.
 * - filterConfig:                  Filter configuration for listings query.
 *    - indexForSearch (optional):    If set as true, it is assumed that the extended data key has
 *                                    search index in place. I.e. the key can be used to filter
 *                                    listing queries (then scope needs to be 'public').
 *                                    Note: Flex CLI can be used to set search index for the key:
 *                                    https://www.sharetribe.com/docs/references/extended-data/#search-schema
 *                                    Read more about filtering listings with public data keys from API Reference:
 *                                    https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
 *                                    Default value: false,
 *   - filterType:                    Sometimes a single schemaType can be rendered with different filter components.
 *                                    For 'enum' schema, filterType can be 'SelectSingleFilter' or 'SelectMultipleFilter'
 *   - label:                         Label for the filter, if the field can be used as query filter
 *   - searchMode (optional):         Search mode for indexed data with multi-enum schema.
 *                                    Possible values: 'has_all' or 'has_any'.
 *   - group:                         SearchPageWithMap has grouped filters. Possible values: 'primary' or 'secondary'.
 * - showConfig:                    Configuration for rendering listing. (How the field should be shown.)
 *   - label:                         Label for the saved data.
 *   - isDetail                       Can be used to hide detail row (of type enum, boolean, or long) from listing page.
 *                                    Default value: true,
 * - saveConfig:                    Configuration for adding and modifying extended data fields.
 *   - label:                         Label for the input field.
 *   - placeholderMessage (optional): Default message for user input.
 *   - isRequired (optional):         Is the field required for providers to fill
 *   - requiredMessage (optional):    Message for those fields, which are mandatory.
 */
export const listingFields = [
  {
    key: 'type_of_issue',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      {
        label: 'Business',
        option: 'business',
      },
      {
        label: 'Personal',
        option: 'personal',
      },
    ],
    filterConfig: {
      indexForSearch: true,
      label: 'Type of issue',
      filterType: 'SelectSingleFilter',
      group: 'primary',
    },
    showConfig: {
      label: 'Type of issue',
      isDetail: true,
    },
    saveConfig: {
      label: 'Type of legal issue',
      question: 'Is this a business or personal legal issue?',
      isRequired: true,
      requiredMessage: 'This field is required',
    },
  },
  {
    key: 'description',
    scope: 'none',
    schemaType: 'text',
    saveConfig: {
      label: 'Description',
      placeholderMessage: 'Describe your issue in more detail',
      isRequired: true,
      requiredMessage: 'This field is required',
      showInPopup: false,
    },
  },
  {
    key: 'category',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      {
        label: 'Accident & Injury',
        option: 'accident-and-injury',
      },
      {
        label: 'Consumer and civil rights',
        option: 'consumer-and-civil-rights',
      },
      {
        label: 'Crime',
        option: 'crime',
      },
      {
        label: 'Employment',
        option: 'employment',
      },
      {
        label: 'Family and relationships',
        option: 'family-and-relationships',
      },
      {
        label: 'Houses, property and neighbours',
        option: 'houses-property-and-neighbours',
      },
      {
        label: 'Immigration and changing countries',
        option: 'immigration-changing-countries',
      },
      {
        label: 'Money and debt',
        option: 'money-and-debt',
      },
      {
        label: 'Social welfare, health and benefits',
        option: 'social-welfare-health-benefits',
      },
    ],
    filterConfig: {
      indexForSearch: true,
      label: 'Category',
      filterType: 'SelectMultipleFilter',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: true,
    },
    saveConfig: {
      label: 'Category',
      question: 'What do you need help with?',
      placeholderMessage: 'Select an option...',
      isRequired: true,
      requiredMessage: 'You need to select a category',
    },
  },
  {
    key: 'type_of_job',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      {
        label: 'Get consultation and advice',
        option: 'get-consultation-and-advice',
      },
      {
        label: 'Send a legal letter',
        option: 'send-a-legal-letter',
      },
      {
        label: 'File a legal claim',
        option: 'file-a-legal-claim',
      },
      {
        label: 'Defend a legal claim',
        option: 'defend-a-legal-claim',
      },
      {
        label: 'Representation at court',
        option: 'representation-at-court',
      },
      {
        label: 'Notary public services',
        option: 'notary-public-services',
      },
    ],
    filterConfig: {
      indexForSearch: true,
      label: 'Type of job',
      filterType: 'SelectMultipleFilter',
      group: 'primary',
    },
    showConfig: {
      label: 'Type of job',
      isDetail: true,
    },
    saveConfig: {
      label: 'Type of job',
      question: 'What legal service do you need?',
      placeholderMessage: 'Select an option...',
      isRequired: true,
      requiredMessage: 'You need to select a category',
    },
  },
  {
    key: 'how_receive_service',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      {
        label: 'Remotely (video call, etc.)',
        option: 'remote',
      },
      {
        label: 'Directly (in-person)',
        option: 'in-person',
      },
      {
        label: 'Either remotely or directly',
        option: 'either-are-fine',
      },
    ],
    filterConfig: {
      indexForSearch: true,
      label: 'Method',
      filterType: 'SelectSingleFilter',
      group: 'primary',
    },
    showConfig: {
      label: 'Would like to receive the service',
      isDetail: true,
    },
    saveConfig: {
      label: 'How would you like to receive this service, remotely or in-person?',
      placeholderMessage: 'Select an option...',
      isRequired: true,
      requiredMessage: 'You need to select a category',
    },
  },
  {
    key: 'location',
    scope: 'none',
    schemaType: 'location',
    saveConfig: {
      label: 'Enter postcode',
      question: 'Where do you need the Lawyer?',
      subLabel: 'The postcode or town for the address where you want the Lawyer.',
      placeholderMessage: '123 Example Street',
      placeRequiredMessage: 'Please select a location',
    },
  },
  {
    key: 'phoneNumber',
    scope: 'none',
    schemaType: 'phone-number',
    saveConfig: {
      label: 'Phone number',
      placeholderMessage: 'Enter your phone number',
      isRequired: true,
      requiredMessage: 'This field is required',
      showInPopup: false,
    },
  }

  // // An example of how to use transaction type specific custom fields and private data.
  // {
  //   key: 'note',
  //   scope: 'public',
  //   includeForListingTypes: ['product-selling'],
  //   schemaType: 'text',
  //   showConfig: {
  //     label: 'Extra notes',
  //   },
  //   saveConfig: {
  //     label: 'Extra notes',
  //     placeholderMessage: 'Some public extra note about this bike...',
  //   },
  // },
  // {
  //   key: 'privatenote',
  //   scope: 'private',
  //   includeForListingTypes: ['daily-booking'],
  //   schemaType: 'text',
  //   saveConfig: {
  //     label: 'Private notes',
  //     placeholderMessage: 'Some private note about this bike...',
  //   },
  // },
];

///////////////////////////////////////////////////////////////////////
// Configurations related to listing types and transaction processes //
///////////////////////////////////////////////////////////////////////

// A presets of supported listing configurations
//
// Note 1: With first iteration of hosted configs, we are unlikely to support
//         multiple listing types, even though this template has some
//         rudimentary support for it.
// Note 2: transaction type is part of listing type. It defines what transaction process and units
//         are used when transaction is created against a specific listing.

/**
 * Configuration options for listing experience:
 * - listingType:     Unique string. This will be saved to listing's public data on
 *                    EditListingWizard.
 * - label            Label for the listing type. Used as microcopy for options to select
 *                    listing type in EditListingWizard.
 * - transactionType  Set of configurations how this listing type will behave when transaction is
 *                    created.
 *   - process          Transaction process.
 *                      The process must match one of the processes that this client app can handle
 *                      (check src/util/transaction.js) and the process must also exists in correct
 *                      marketplace environment.
 *   - alias            Valid alias for the aforementioned process. This will be saved to listing's
 *                      public data as transctionProcessAlias and transaction is initiated with this.
 *   - unitType         Unit type is mainly used as pricing unit. This will be saved to
 *                      transaction's protected data.
 *                      Recommendation: don't use same unit types in completely different processes
 *                      ('item' sold should not be priced the same as 'item' booked).
 * - stockType        This is relevant only to listings with product-selling listing type.
 *                    If set to 'oneItem', stock management is not showed and the listing is
 *                    considered unique (stock = 1).
 *                    Possible values: 'oneItem' and 'multipleItems'.
 *                    Default: 'multipleItems'.
 */

export const listingTypes = [
  {
    listingType: 'job',
    label: 'Job',
    transactionType: {
      process: 'default-purchase',
      alias: 'default-purchase/release-1',
      unitType: 'item',
    },
    stockType: 'oneItem',
  },
  // // Here are some examples for other listingTypes
  // // TODO: SearchPage does not work well if both booking and product selling are used at the same time
  // {
  //   listingType: 'nightly-booking',
  //   label: 'Nightly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'night',
  //   },
  // },
  // {
  //   listingType: 'hourly-booking',
  //   label: 'Hourly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'hour',
  //   },
  // },
  // {
  //   listingType: 'product-selling',
  //   label: 'Sell bicycles',
  //   transactionType: {
  //     process: 'default-purchase',
  //     alias: 'default-purchase/release-1',
  //     unitType: 'item',
  //   },
  //   stockType: 'multipleItems',
  // },
];

// SearchPage can enforce listing query to only those listings with valid listingType
// However, it only works if you have set 'enum' type search schema for the public data fields
//   - listingType
//
//  Similar setup could be expanded to 2 other extended data fields:
//   - transactionProcessAlias
//   - unitType
//
// Read More:
// https://www.sharetribe.com/docs/how-to/manage-search-schemas-with-flex-cli/#adding-listing-search-schemas
export const enforceValidListingType = false;

export const displayAddressTypeLimit = ['locality', 'place', 'region', 'country'];

export const getConfigByKey = key => listingFields.find(item => item.key === key);
