import React from 'react';
import { FormattedMessage } from 'react-intl';
import { bool, string, shape, func } from 'prop-types';
import { PrimaryButton } from '../Button/Button';
import { propTypes } from '../../util/types';
import { createResourceLocatorString } from '../../util/routes';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';

const ContactButton = props => {
  const {
    className,
    user,
    disabled,
    openContactModal,
    currentUser,
    history,
  } = props;

  const routeConfiguration = useRouteConfiguration();
  const { displayName } = user.attributes.profile;

  const handleClickButton = e => {
    e.preventDefault();

    // Signup and return back to Profilse Setting Page
    if (!currentUser || !currentUser.id) {
      history.push(createResourceLocatorString('ProfileSettingsPage', routeConfiguration, {}, {}));
    } else {
      openContactModal();
    }
  };

  return (
    <PrimaryButton className={className} disabled={disabled} onClick={handleClickButton}>
      <FormattedMessage id="ContactButton.contact" values={{ displayName }} />
    </PrimaryButton>
  );
};

ContactButton.propsTypes = {
  className: string,
  user: propTypes.user.isRequired,
  disabled: bool,
  currentUser: propTypes.currentUser,
  history: shape({
    push: func.isRequired,
  }).isRequired, 
};

export default ContactButton;
