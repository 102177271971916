import React, { useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import Button from '../Button/Button';
import { H2, H5 } from '../Heading/Heading';
import { func, string } from 'prop-types';
import downIcon from './chevron-down.png';
import css from './ContactModal.module.css';
import logo from './logo.png';

const SubscriptionNeededContent = props => {
  const { onSubscribe, authorDisplayName } = props;

  const [creditDropdownOpen, setCreditDropdownOpen] = useState(false);

  return (
    <>
      <H2 className={css.title}>
        <FormattedMessage
          id="ContactModal.subscriptionNeeded.title"
          values={{ author: authorDisplayName }}
        />
      </H2>
      <H5 className={css.subtitle}>
        <FormattedMessage id="ContactModal.subscriptionNeeded.subtitle" />
      </H5>

      <div className={css.dropdown}>
        <button
          className={css.dropdownTitle}
          onClick={() => setCreditDropdownOpen(!creditDropdownOpen)}
        >
          <FormattedMessage id="ContactModal.subscriptionNeeded.whatAreCreditsTitle" />
          <img
            className={css.right}
            alt="A chevron pointing down"
            src={downIcon}
            height={25}
            width={25}
          />
        </button>
        {creditDropdownOpen && (
          <div className={css.dropdownContent}>
            <FormattedMessage id="ContactModal.subscriptionNeeded.whatAreCreditsContent" />
          </div>
        )}
      </div>

      <div className={css.insight}>
        <p>
          <strong>
            <FormattedMessage id="ContactModal.subscriptionNeeded.insightTitle" />
          </strong>
        </p>
        <p>
          <FormattedMessage id="ContactModal.subscriptionNeeded.insightContent" />
        </p>
      </div>

      <div className={css.priceBox}>
        <div className={css.offerTitle}>
          <div className={css.title}>
            <FormattedMessage id="ContactModal.subscriptionNeeded.exclusiveTitle" />
          </div>
          <div className={css.percent}>
            <FormattedMessage id="ContactModal.subscriptionNeeded.exclusivePercent" />
          </div>
        </div>

        <div className={css.price}>
          <p>
            <img src={logo} width={20} height={20} />
            <FormattedMessage
              id="ContactModal.subscriptionNeeded.50credits"
              values={{
                br: () => <br />,
                b: data => <strong>{data}</strong>,
              }}
            />
          </p>
          <div className={css.priceDisplay}>
            <div>
              <FormattedMessage
                id="ContactModal.subscriptionNeeded.50creditsPrice"
                values={{
                  b: data => <strong>{data}</strong>,
                }}
              />
            </div>
            <div className={css.originalPrice}>
              <FormattedMessage id="ContactModal.subscriptionNeeded.50creditsOriginalPrice" />
            </div>
          </div>
        </div>

        <Button onClick={onSubscribe} className={css.button}>
          <FormattedMessage id="ContactModal.subscriptionNeeded.primaryButton" />
        </Button>
      </div>
    </>
  );
};

SubscriptionNeededContent.propTypes = {
  authorDisplayName: string.isRequired,
  onSubscribe: func.isRequired,
};

export default SubscriptionNeededContent;
