import * as React from 'react';

const IconCrime = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={800}
    height={800}
    viewBox="0 0 508.125 508.125"
    {...props}
  >
    <path d="M393.025.072c-41 0-79.2 22.7-99.6 58.4h-38.8c-8.3.3-14.1 6.7-14.1 14.1v31.1c-22.5 4.4-41.4 19.3-51 41.3-11.7 26.8-6.6 56.7 13.3 78l.6.7c12 12.8 13.9 31.5 6.5 46.4l-17.7-17.9c-5.3-5.4-14.8-5.4-20.1 0l-27.4 27.8c-39.4-10.7-82.2.6-111.2 29.9-44.7 45.3-44.7 118.9 0 164.2 43.1 44 115.4 46.6 162.7 0 29-29.3 40.1-72.6 29.5-112.5l27.6-27.9c5.4-5.5 5.4-14.3 0-19.8l-22.7-23c17.9-26.2 16-62.3-6.4-86.4l-.6-.7c-14.5-15.5-13.7-34.7-8.1-47.5 5-11.6 14-19.7 25.1-23.5v27.1c0 7.8 6.3 14.1 14.1 14.1h38.8c20.4 35.8 58.6 58.4 99.6 58.4 63.4 0 115-52.1 115-116.2-.1-64-51.7-116.1-115.1-116.1zm-196.6 362.3c11.2 32 3.3 68.1-20.3 91.9-40.2 39.7-94.3 28.7-122.5 0-33.9-34.3-33.9-90.2 0-124.5 23.5-23.7 58.9-31.8 90.4-20.5 5.2 1.9 11 .6 14.8-3.4l23.4-23.7 41.1 41.6-23.7 24c-3.7 3.8-5 9.5-3.2 14.6zm196.6-158.1c-33.2 0-63.9-19.8-78.3-50.3-2.3-4.9-7.3-8.1-12.8-8.1h-33.2v-59.1h33.2c5.5 0 10.4-3.2 12.8-8.1 14.5-30.6 45.2-50.4 78.3-50.4 47.8 0 86.7 39.5 86.7 88 .1 48.5-38.8 88-86.7 88z" />
    <path d="M391.825 54.772c-34 0-61.6 27.6-61.6 61.6s27.6 61.6 61.6 61.6 61.6-27.6 61.6-61.6c-.1-34.1-27.6-61.6-61.6-61.6zm0 94.8c-18.4 0-33.3-15-33.3-33.3 0-18.4 15-33.3 33.3-33.3 18.4 0 33.3 15 33.3 33.3 0 18.4-14.9 33.3-33.3 33.3zM116.225 328.872c-34.1 0-61.8 27.7-61.8 61.8s27.7 61.8 61.8 61.8c34.1 0 61.8-27.7 61.8-61.8 0-34.201-27.7-61.8-61.8-61.8zm0 95.3c-18.5 0-33.6-15.1-33.6-33.6s15.1-33.6 33.6-33.6 33.6 15.1 33.6 33.6c0 18.6-15.1 33.6-33.6 33.6z" />
  </svg>
);
export default IconCrime;
