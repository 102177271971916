import React from "react";
import classNames from "classnames";
import { useConfiguration } from "../../context/configurationContext";
import IconAnchor from "../IconAnchor/IconAnchor";

import css from "./ListingAddress.module.css";

const getDisplayAddress = (fragmentAddress, pickedType, separator = ', ') => {
  if (!fragmentAddress) {
    return '';
  }

  return fragmentAddress
    .reduce((acc, { text, type }) => {
      if (pickedType.includes(type)) {
        acc.push(text);
      }
      return acc;
    }, [])
    .join(separator);
};

const ListingAddress = props => {
  const { className, location } = props;
  const { listing } = useConfiguration();

  const displayAddress = getDisplayAddress(location?.fragment, listing.displayAddressTypeLimit);
  if (!displayAddress) {
    return null;
  }

  const classes = classNames(css.addressContainer, className);
  return (
    <div className={classes}>
      <IconAnchor className={css.icon} />
      <span>{displayAddress}</span>
    </div>
  )
};

export default ListingAddress;