import * as React from "react"
const IconHouse = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="_x32_"
    width={800}
    height={800}
    viewBox="0 0 512 512"
    {...props}
  >
    <style>{".st0{fill:#000}"}</style>
    <path
      d="m442.531 218-97.703-97.703L256 31.469l-88.828 88.828-97.734 97.75L0 287.469l39.984 39.984 69.422-69.422 97.75-97.75L256 111.438l48.844 48.843 97.687 97.703 69.485 69.469L512 287.469z"
      className="st0"
    />
    <path
      d="M85.719 330.375v150.156H274.75V361.547h68.828v118.984h82.703V330.328L256.016 160.063z"
      className="st0"
    />
  </svg>
)
export default IconHouse
