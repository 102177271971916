import React, { useState } from 'react';
import PropTypes, { func } from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import Select from 'react-select';
import { ValidationError } from '..';
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import AsyncCreatableSelect from 'react-select/async-creatable';

import css from './FieldSelectCustom.module.css';

const DEFAULT_CUSTOM_STYLE = {
  control: style => ({
    ...style,
    display: 'flex',
    borderRadius: 6,
    paddingLeft: 8,
    borderColor: 'var(--colorFieldBorder)',
    boxShadow: 'none',
    '&:hover': {
      borderColor: 'var(--colorFieldBorderHover)',
      boxShadow: '0 0 0 0.2rem rgb(0 123 255 / 25%)',
    },
    minHeight: '56px',
  }),
  valueContainer: styles => ({
    ...styles,
    paddingTop: 6,
    paddingBottom: 6,
  }),
  placeholder: defaultStyles => {
    return {
      ...defaultStyles,
      color: 'var(--matterColorAnti)',
    };
  },
};

const FieldSelectCustomComponent = props => {
  const {
    rootClassName,
    className,
    description,
    id,
    label,
    input,
    meta,
    options,
    isAsync,
    inputValidate,
    placeholder,
    onInputChange,
    showDefaultOptions,
    loadOptions,
    useDefaultStyles,
    isCreatable,
    customStyles,
    onChange,
    handleKeyDown,
    noteMessage,
    noteMessageClassName,
    ...rest
  } = props;

  if (label && !id) {
    throw new Error('id required when a label is given');
  }
  const { valid, invalid, touched, error } = meta;
  const [customErrorText, setCustomErrorText] = useState(undefined);

  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const errorText = customErrorText || error;
  const hasError = !!customErrorText || !!(touched && invalid && error);
  const fieldMeta = { touched: hasError, error: errorText };

  const classes = classNames(css.select, css.root || rootClassName, {
    [css.selectSuccess]: valid,
    [css.selectError]: hasError,
  });

  const getSelectComponent = () => {
    if (isAsync && isCreatable) {
      return <AsyncCreatableSelect cacheOptions loadOptions={loadOptions} {...selectProps} />;
    }

    if (isAsync) {
      return <AsyncSelect loadOptions={loadOptions} {...selectProps} />;
    }

    if (isCreatable) {
      return <CreatableSelect {...selectProps} isSearchable />;
    }

    return <Select {...selectProps} />;
  };

  const handleChange = selection => {
    input.onChange(selection);

    if (onChange) {
      onChange(selection);
    }
  };

  const selectProps = {
    className: useDefaultStyles ? '' : classes,
    id,
    options,
    styles: useDefaultStyles ? {} : { ...DEFAULT_CUSTOM_STYLE, ...customStyles },
    onInputChange: onInputChange
      ? onInputChange
      : value => {
          const error =
            inputValidate && typeof inputValidate === 'function' ? inputValidate(value) : null;
          setCustomErrorText(error);
        },
    onKeyDown: handleKeyDown,
    defaultOptions: showDefaultOptions,
    placeholder,
    ...input,
    onChange: handleChange,
    ...meta,
    ...rest,
  };

  return (
    <div className={rootClassName || className}>
      {label ? <label htmlFor={id}>{label}</label> : null}
      {description ? <p className={css.description}>{description}</p> : null}
      {getSelectComponent()}
      {noteMessage && (
        <div className={classNames(css.note, noteMessageClassName)}>{noteMessage}</div>
      )}
      <ValidationError fieldMeta={fieldMeta} />
    </div>
  );
};

FieldSelectCustomComponent.defaultProps = {
  rootClassName: null,
  className: null,
  id: null,
  label: null,
  children: null,
  isAsync: false,
  cacheOptions: false,
  showDefaultOptions: false,
  isMulti: false,
  useDefaultStyles: false,
  isCreatable: false,
  noteMessage: null,
  noteMessageClassName: null,
  customStyles: {},
  onChange: () => {},
  handleKeyDown: () => {},
};

const { string, object, node, bool } = PropTypes;

FieldSelectCustomComponent.propTypes = {
  rootClassName: string,
  className: string,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,

  // Generated by final-form's Field component
  input: object.isRequired,
  meta: object.isRequired,
  placeholder: string,

  children: node,

  description: string,

  isMulti: bool,
  isAsync: bool,
  inputValidate: func,
  cacheOptions: bool,
  showDefaultOptions: bool,
  blurInputOnSelect: bool,
  loadOptions: func,
  getOptionLabel: func,
  getOptionValue: func,
  useDefaultStyles: bool,
  isCreatable: bool,
  customStyle: object,
  onChange: func,
  handleKeyDown: func,
  noteMessage: string,
  noteMessageClassName: string,
};

const FieldSelectCustom = props => {
  return <Field component={FieldSelectCustomComponent} {...props} />;
};

export default FieldSelectCustom;
