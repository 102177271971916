import React from 'react';
import { bool, func, object, string } from 'prop-types';
import { Field } from 'react-final-form';
import AspectRatioWrapper from '../AspectRatioWrapper/AspectRatioWrapper';

import css from './FieldAddFile.module.css';

const ASPECT_WIDTH = 1;
const ASPECT_HEIGHT = 1 / 2;

// Field component that uses file-input to allow user to select files.
const FieldAddFile = props => {
  const { onFileUploadHandler, ...rest } = props;

  return (
    <Field {...rest}>
      {fieldprops => {
        const { accept, input, label, disabled } = fieldprops;
        const { name, type } = input;
        const onChange = e => {
          const file = e.target.files[0];
          onFileUploadHandler(file);
        };
        const inputProps = { accept, id: name, name, onChange, type };

        return (
          <div className={css.addFileWrapper}>
            <AspectRatioWrapper width={ASPECT_WIDTH} height={ASPECT_HEIGHT}>
              <input {...inputProps} disabled={disabled} className={css.addFileInput} />
              <label htmlFor={name} className={css.addFile}>
                {label}
              </label>
            </AspectRatioWrapper>
          </div>
        );
      }}
    </Field>
  );
};

FieldAddFile.propTypes = {
  onFileUploadHandler: func.isRequired,
  disabled: bool,
  type: string.isRequired,
  label: object.isRequired,
};

export default FieldAddFile;

