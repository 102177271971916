import classNames from 'classnames';
import { string } from 'prop-types';
import React from 'react';

import css from './IconAnimatedCheckmark.module.css';

const IconAnimatedCheckmark = props => {
  const { className, rootClassName } = props;

  const classes = classNames(rootClassName ?? css.root, className);
  return (
    <svg className={classes} viewBox="0 0 52 52">
      <circle className={css.circle} cx="26" cy="26" r="25" fill="none" />
      <path className={css.check} fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
    </svg>
  );
};

IconAnimatedCheckmark.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconAnimatedCheckmark;
