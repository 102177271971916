import React from 'react';
import { func, object, string } from 'prop-types';
import classNames from 'classnames';

// Import modules from this directory
import { truncate } from 'lodash';
import IconFile from '../IconFile/IconFile';
import IconClose from '../IconClose/IconClose';

import css from './FileDocument.module.css';

const MAX_FILENAME_LENGTH = 30;

const truncateFileName = fileName => {
  const fileNameComponents = fileName.split('.');
  return `${truncate(fileNameComponents.slice(0, -1).join('.'), {
    length: MAX_FILENAME_LENGTH,
  })}.${fileNameComponents.pop()}`;
};

// Cropped "thumbnail" of given listing file.
// The file might be one already uploaded and attached to listing entity
// or representing local file file (before it's uploaded & attached to listing).
const FileDocument = props => {
  const { className, removeBtnClassName, file, onRemoveFile } = props;
  const fileName = file.id || file.name;
  const handleRemoveClick = e => {
    e.stopPropagation();
    onRemoveFile(file.id);
  };

  const removeBtnClasses = classNames(css.removeFile, removeBtnClassName);

  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      <div className={css.fileWrapper}>
          <div className={css.file}>
            <div className={css.fileName}>
              <IconFile />
              <span>{truncateFileName(fileName)}</span>
            </div>
            <aside>
              <IconClose className={removeBtnClasses} onClick={handleRemoveClick} />
            </aside>
          </div>
      </div>
    </div>
  );
};

FileDocument.defaultProps = { className: null };

FileDocument.propTypes = {
  className: string,
  file: object.isRequired,
  onRemoveFile: func.isRequired,
};

export default FileDocument;
