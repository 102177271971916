import * as React from "react"
const IconLaw = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={800}
    height={800}
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M22.951 11.259a.919.919 0 0 0-.057-.189c-.007-.014-.006-.03-.013-.044l-3.5-6.5a.913.913 0 0 0-.142-.187A.989.989 0 0 0 18.5 4H13V2a1 1 0 0 0-2 0v2H5.5a.989.989 0 0 0-.739.339.913.913 0 0 0-.142.187l-3.5 6.5c-.007.014-.006.03-.013.044a.91.91 0 0 0-.057.188.98.98 0 0 0-.04.2c0 .015-.009.028-.009.044a4.5 4.5 0 0 0 9 0c0-.016-.008-.029-.009-.044a.97.97 0 0 0-.04-.2.919.919 0 0 0-.057-.189c-.007-.014-.006-.03-.013-.044L7.174 6H11v12H8a1 1 0 0 0 0 2h8a1 1 0 0 0 0-2h-3V6h3.826l-2.707 5.026c-.007.014-.006.03-.013.044a.91.91 0 0 0-.057.188.98.98 0 0 0-.04.2c0 .015-.009.028-.009.044a4.5 4.5 0 0 0 9 0c0-.016-.008-.029-.009-.044a.97.97 0 0 0-.04-.199ZM5.5 14a2.5 2.5 0 0 1-2.291-1.5h4.582A2.5 2.5 0 0 1 5.5 14Zm1.826-3.5H3.674L5.5 7.109ZM18.5 7.109l1.826 3.391h-3.652Zm0 6.891a2.5 2.5 0 0 1-2.291-1.5h4.582A2.5 2.5 0 0 1 18.5 14Zm2.5 8a1 1 0 0 1-1 1H4a1 1 0 0 1 0-2h16a1 1 0 0 1 1 1Z" />
  </svg>
)
export default IconLaw
