import { bool, func, node, object, string } from 'prop-types';
import React, { Children, useMemo, useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { useIntl } from 'react-intl';
import Button, { SecondaryButton } from '../Button/Button';
import Form from '../Form/Form';

import css from './FormWizard.module.css';

const FormWizard = props => {
  const { initialValues, onSubmit, submitInProgress, errorText, children } = props;
  const intl = useIntl();
  const [pageIndex, setPageIndex] = useState(0);
  const pages = useMemo(() => Children.toArray(children), [children]);
  const currentPage = pages[pageIndex];
  const inFirstPage = pageIndex === 0;
  const inLastPage = pageIndex === pages.length - 1;

  const goToNextPage = () => setPageIndex(index => (!inLastPage ? index + 1 : index));
  const goToPrevPage = () => setPageIndex(index => (!inFirstPage ? index - 1 : index));

  const handleSubmit = values => {
    if (!inLastPage) {
      goToNextPage();
      return;
    }
    onSubmit(values);
  };

  return (
    <FinalForm
      initialValues={initialValues}
      onSubmit={handleSubmit}
      render={formRenderProps => {
        const { handleSubmit, invalid } = formRenderProps;

        const prevButtonLabel = intl.formatMessage({ id: 'FormWizard.prevButtonLabel' });
        const nextButtonLabel = intl.formatMessage({ id: 'FormWizard.nextButtonLabel' });
        const submitButtonLabel = intl.formatMessage({ id: 'FormWizard.submitButtonLabel' });

        const prevButton = !inFirstPage ? (
          <SecondaryButton
            className={css.prevButton}
            type="button"
            onClick={goToPrevPage}
            disabled={submitInProgress}
          >
            {prevButtonLabel}
          </SecondaryButton>
        ) : null;

        const actionButton = inLastPage ? (
          <Button
            className={css.actionButton}
            type="submit"
            disabled={invalid || submitInProgress}
            inProgress={submitInProgress}
          >
            {submitButtonLabel}
          </Button>
        ) : (
          <Button
            className={css.actionButton}
            type="button"
            onClick={goToNextPage}
            disabled={invalid}
          >
            {nextButtonLabel}
          </Button>
        );

        return (
          <Form className={css.form} onSubmit={handleSubmit}>
            {currentPage}
            {errorText ? <p className={css.error}>{errorText}</p> : null}
            <div className={css.buttons}>
              {prevButton}
              {actionButton}
            </div>
          </Form>
        );
      }}
    />
  );
};

FormWizard.defaultProps = {
  initialValues: {},
  submitInProgress: false,
};

FormWizard.propTypes = {
  initialValues: object,
  onSubmit: func.isRequired,
  submitInProgress: bool,
  errorText: string,
  children: node.isRequired,
};

export default FormWizard;
