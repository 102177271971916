import React from 'react';
import { shape, bool, func } from 'prop-types';
import { connect } from 'react-redux';
import { manageDisableScrolling } from '../../ducks/ui.duck';
import { ensureCurrentUser, ensureUser, userDisplayNameAsString } from '../../util/data';
import { propTypes } from '../../util/types';
import defaultConfig from '../../config/configDefault';
import { createResourceLocatorString } from '../../util/routes';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { sendInquiry } from '../../ducks/listing.duck';
import Modal from '../Modal/Modal';
import InquiryForm from './InquiryForm/InquiryForm';
import SubscriptionNeededContent from './SubscriptionNeededContent';
import MoreCreditsNeededContent from './MoreCreditsNeededContent';

import css from './ContactModal.module.css';

const ContactModal = props => {
  const routeConfiguration = useRouteConfiguration();

  const {
    onManageDisableScrolling,
    listing,
    onClose,
    currentUser,
    isOpen,
    onSendInquiry,
    sendInquiryInProgress,
    sendInquiryError,
    onSubscribe,
    onSaveCurrentState,
    currentCredits,
    history,
  } = props;

  if (!currentUser || !listing) return null;

  const { title = '' } = listing.attributes;

  const authorAvailable = listing && listing.author;
  const currentAuthor = authorAvailable ? listing.author : null;
  const ensuredAuthor = ensureUser(currentAuthor);
  const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');
  const ensuredCurrentUser = ensureCurrentUser(currentUser);

  const {
    metadata: { subscription },
  } = ensuredCurrentUser.attributes.profile;

  const onSubmitInquiry = async values => {
    const { message } = values;

    const txId = await onSendInquiry(listing, message.trim());

    // Redirect to OrderDetailsPage
    history.push(
      createResourceLocatorString('OrderDetailsPage', routeConfiguration, { id: txId.uuid }, {})
    );
  };

  const renderContentForModal = () => {
    if (!subscription) {
      return (
        <SubscriptionNeededContent
          authorDisplayName={authorDisplayName}
          onSubscribe={onSubscribe}
        />
      );
    }

    const requiredCredits =
      listing.attributes.publicData.requiredCredits ??
      defaultConfig.subscription.defaultRequiredCredits;

    if (currentCredits < requiredCredits) {
      return (
        <MoreCreditsNeededContent
          currentCredits={currentCredits}
          onSaveCurrentState={onSaveCurrentState}
          requiredCredits={requiredCredits}
        />
      );
    }

    return (
      <InquiryForm
        className={css.inquiryForm}
        submitButtonWrapperClassName={css.inquirySubmitButtonWrapper}
        listingTitle={title}
        authorDisplayName={authorDisplayName}
        sendInquiryError={sendInquiryError}
        onSubmit={onSubmitInquiry}
        inProgress={sendInquiryInProgress}
        requiredCredits={requiredCredits}
      />
    );
  };

  return (
    <Modal
      id="contact-user-modal"
      isOpen={isOpen}
      onClose={onClose}
      onManageDisableScrolling={onManageDisableScrolling}
    >
      {renderContentForModal()}
    </Modal>
  );
};

const mapStateToProps = state => {
  const { currentUser, currentCredits } = state.user;
  const { sendInquiryInProgress, sendInquiryError } = state.listing;

  return {
    currentUser,
    currentCredits,
    sendInquiryInProgress,
    sendInquiryError,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onSendInquiry: (listing, message) => dispatch(sendInquiry(listing, message)),
});

ContactModal.defaultProps = {
  isContactModalOpen: false,
  isOpen: false,
  sendInquiryInProgress: false,
  sendInquiryError: null,
};

ContactModal.propTypes = {
  onManageDisableScrolling: func.isRequired,
  listing: propTypes.listing,
  isContactModalOpen: bool,
  currentUser: propTypes.currentUser,
  onClose: func.isRequired,
  isOpen: bool.isRequired,
  onUpdateDocuments: func.isRequired,
  onUpdateProfile: func.isRequired,
  onSendInquiry: func.isRequired,
  onSubscribe: func.isRequired,
  onSaveCurrentState: func.isRequired,
  sendInquiryInProgress: bool,
  sendInquiryError: propTypes.error,
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactModal);
