import React from 'react';
import { string, func, oneOfType, shape } from 'prop-types';
import classNames from 'classnames';

import { ensureUser, ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';

import { AvatarLarge, Heading, ListingAddress, ContactButton } from '../../components';
import ContactInfo from '../ContactInfo/ContactInfo';

import css from './UserCard.module.css';

const UserCard = props => {
  const {
    rootClassName,
    className,
    user,
    currentUser,
    location,
    openContactModal,
    history,
    contactInfo,
    fetchContactInfoError,
  } = props;

  const userIsCurrentUser = user && user.type === 'currentUser';
  const ensuredUser = userIsCurrentUser ? ensureCurrentUser(user) : ensureUser(user);

  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const {
    metadata: { isVerified } = {},
    privateData: { certificationDocuments = [] } = {},
  } = ensuredCurrentUser.attributes.profile;

  const isCurrentUser =
    ensuredUser.id && ensuredCurrentUser.id && ensuredUser.id.uuid === ensuredCurrentUser.id.uuid;
  const adminNotVerifiedYet = certificationDocuments.length > 0 && !isVerified;
  const disabledButton = isCurrentUser || adminNotVerifiedYet;

  const { displayName } = ensuredUser.attributes.profile;

  const contactButtonProps = {
    user: ensuredUser,
    openContactModal,
    currentUser,
    history,
  };

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.content}>
        <AvatarLarge className={css.avatar} user={user} />
        <div className={css.infoContainer}>
          <div className={css.info}>
            <div className={css.headingRow}>
              <Heading as="h3" className={css.heading}>
                {displayName}
              </Heading>
            </div>
            <ContactInfo contactInfo={contactInfo} fetchContactInfoError={fetchContactInfoError} />
            <ListingAddress location={location} />
          </div>
          {!isCurrentUser && (
            <ContactButton
              className={css.contactButton}
              disabled={disabledButton}
              {...contactButtonProps}
            />
          )}
        </div>
      </div>
      <div className={css.contactButtonContainerInMobile}>
        <ContactButton disabled={disabledButton} {...contactButtonProps} />
      </div>
    </div>
  );
};

UserCard.defaultProps = {
  rootClassName: null,
  className: null,
  user: null,
  currentUser: null,
};

UserCard.propTypes = {
  rootClassName: string,
  className: string,
  user: oneOfType([propTypes.user, propTypes.currentUser]),
  currentUser: propTypes.currentUser,
  onContactUser: func,
  history: shape({
    push: func.isRequired,
  }).isRequired,  
};

export default UserCard;
