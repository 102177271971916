import { contactListing } from '../util/api';
import { storableError } from '../util/errors';
import * as log from '../util/log';
import { fetchCurrentUserHasOrdersSuccess } from './user.duck';

// ================ Action types ================ //

export const SEND_INQUIRY_REQUEST = 'app/listing/SEND_INQUIRY_REQUEST';
export const SEND_INQUIRY_SUCCESS = 'app/listing/SEND_INQUIRY_SUCCESS';
export const SEND_INQUIRY_ERROR = 'app/listing/SEND_INQUIRY_ERROR';

// ================ Reducer ================ //

const initialState = {
  sendInquiryInProgress: false,
  sendInquiryError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SEND_INQUIRY_REQUEST:
      return { ...state, sendInquiryInProgress: true, sendInquiryError: null };
    case SEND_INQUIRY_SUCCESS:
      return { ...state, sendInquiryInProgress: false };
    case SEND_INQUIRY_ERROR:
      return { ...state, sendInquiryInProgress: false, sendInquiryError: payload };

    default:
      return state;
  }
};

// ================ Action creators ================ //

export const sendInquiryRequest = () => ({ type: SEND_INQUIRY_REQUEST });
export const sendInquirySuccess = () => ({ type: SEND_INQUIRY_SUCCESS });
export const sendInquiryError = e => ({ type: SEND_INQUIRY_ERROR, error: true, payload: e });

// ================ Thunks ================ //

export const sendInquiry = (listing, message) => (dispatch, getState, sdk) => {
  dispatch(sendInquiryRequest());
  const processAlias = listing?.attributes?.publicData?.transactionProcessAlias;
  const listingId = listing?.id?.uuid;

  if (!processAlias) {
    const error = new Error('No transaction process attached to listing');
    log.error(error, 'listing-process-missing', {
      listingId,
    });
    dispatch(sendInquiryError(storableError(error)));
    return Promise.reject(error);
  }

  return contactListing({ listingId, content: message })
    .then(response => {
      const transactionId = response.data.id;
      dispatch(sendInquirySuccess());
      dispatch(fetchCurrentUserHasOrdersSuccess(true));

      return transactionId;
    })
    .catch(e => {
      dispatch(sendInquiryError(storableError(e)));
      throw e;
    });
};
