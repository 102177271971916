import { fetchSubscriptionPacks } from '../../util/api';
import { storableError } from '../../util/errors';

// ================ Action types ================ //
export const FETCH_SUBSCRIPTION_PACKS_REQUEST =
  'app/PricingTiersPage/FETCH_SUBSCRIPTION_PACKS_REQUEST';
export const FETCH_SUBSCRIPTION_PACKS_SUCCESS =
  'app/PricingTiersPage/FETCH_SUBSCRIPTION_PACKS_SUCCESS';
export const FETCH_SUBSCRIPTION_PACKS_ERROR = 'app/PricingTiersPage/FETCH_SUBSCRIPTION_PACKS_ERROR';

// ================ Reducer ================ //

const initialState = {
  subscriptionPacks: [],
  fetchSubscriptionPacksInProgress: false,
  fetchSubscriptionPacksError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_SUBSCRIPTION_PACKS_REQUEST:
      return {
        ...state,
        fetchSubscriptionPacksInProgress: true,
        fetchSubscriptionPacksError: null,
      };
    case FETCH_SUBSCRIPTION_PACKS_SUCCESS:
      return {
        ...state,
        subscriptionPacks: payload.subscriptionPacks,
        fetchSubscriptionPacksInProgress: false,
      };
    case FETCH_SUBSCRIPTION_PACKS_ERROR:
      return {
        ...state,
        fetchSubscriptionPacksInProgress: false,
        fetchSubscriptionPacksError: payload.error,
      };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const fetchSubscriptionPacksRequest = () => ({
  type: FETCH_SUBSCRIPTION_PACKS_REQUEST,
});

export const fetchSubscriptionPacksSuccess = subscriptionPacks => ({
  type: FETCH_SUBSCRIPTION_PACKS_SUCCESS,
  payload: { subscriptionPacks },
});

export const fetchSubscriptionPacksError = error => ({
  type: FETCH_SUBSCRIPTION_PACKS_ERROR,
  payload: { error },
});

const getSubscriptionPacks = () => (dispatch, getState, sdk) => {
  dispatch(fetchSubscriptionPacksRequest());
  return fetchSubscriptionPacks()
    .then(result => dispatch(fetchSubscriptionPacksSuccess(result.data)))
    .catch(e => dispatch(fetchSubscriptionPacksError(storableError(e))));
};

export const loadData = () => {
  return getSubscriptionPacks();
};
