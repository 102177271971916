import * as React from "react"
const IconBriefcase = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    width={800}
    height={800}
    data-name="Layer 1"
    viewBox="0 0 32 32"
    {...props}
  >
    <defs>
      <style>{".cls-1{fill:#4d4d4d}"}</style>
    </defs>
    <title />
    <path
      d="M28 8h-7V6a2 2 0 0 0-2-2h-6a2 2 0 0 0-2 2v2H4a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h24a2 2 0 0 0 2-2V10a2 2 0 0 0-2-2ZM13 6h6v2h-6Zm15 4v9H4v-9ZM4 26v-5h24v5Z"
      className="cls-1"
    />
    <path d="M15 18h2a1 1 0 0 0 0-2h-2a1 1 0 0 0 0 2Z" className="cls-1" />
  </svg>
)
export default IconBriefcase
