import pick from 'lodash/pick';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { fetchSubscriptionPack } from '../../util/api';
import * as log from '../../util/log';
import { storableError } from '../../util/errors';

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/CheckoutSubscriptionPage/SET_INITIAL_VALUES';
export const SETUP_INTENT_REQUEST = 'app/CheckoutSubscriptionPage/SETUP_INTENT_REQUEST';
export const SETUP_INTENT_SUCCESS = 'app/CheckoutSubscriptionPage/SETUP_INTENT_SUCCESS';
export const SETUP_INTENT_ERROR = 'app/CheckoutSubscriptionPage/SETUP_INTENT_ERROR';
export const STRIPE_CUSTOMER_REQUEST = 'app/CheckoutSubscriptionPage/STRIPE_CUSTOMER_REQUEST';
export const STRIPE_CUSTOMER_SUCCESS = 'app/CheckoutSubscriptionPage/STRIPE_CUSTOMER_SUCCESS';
export const STRIPE_CUSTOMER_ERROR = 'app/CheckoutSubscriptionPage/STRIPE_CUSTOMER_ERROR';
export const FETCH_SUBSCRIPTION_PACK_REQUEST =
  'app/CheckoutSubscriptionPage/FETCH_SUBSCRIPTION_PACK_REQUEST';
export const FETCH_SUBSCRIPTION_PACK_SUCCESS =
  'app/CheckoutSubscriptionPage/FETCH_SUBSCRIPTION_PACK_SUCCESS';
export const FETCH_SUBSCRIPTION_PACK_ERROR =
  'app/CheckoutSubscriptionPage/FETCH_SUBSCRIPTION_PACK_ERROR';

// ================ Reducer ================ //

const initialState = {
  orderData: null,
  fetchStripeCustomerInProgress: false,
  stripeCustomerFetchError: null,
  setupIntentInProgress: false,
  setupIntentError: null,
  fetchSubscriptionPackInProgress: false,
  subscriptionPackFetchError: null,
  subscriptionPack: null,
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case STRIPE_CUSTOMER_REQUEST:
      return { ...state, fetchStripeCustomerInProgress: true, stripeCustomerFetchError: null };
    case STRIPE_CUSTOMER_SUCCESS:
      return { ...state, fetchStripeCustomerInProgress: false };
    case STRIPE_CUSTOMER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, stripeCustomerFetchError: payload, fetchStripeCustomerInProgress: false };

    case SETUP_INTENT_REQUEST:
      return { ...state, setupIntentInProgress: true, setupIntentError: null };
    case SETUP_INTENT_SUCCESS:
      return {
        ...state,
        setupIntentInProgress: false,
        setupIntentError: null,
      };
    case SETUP_INTENT_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, setupIntentInProgress: false, setupIntentError: null };

    case FETCH_SUBSCRIPTION_PACK_REQUEST:
      return { ...state, fetchSubscriptionPackInProgress: true, subscriptionPackFetchError: null };
    case FETCH_SUBSCRIPTION_PACK_SUCCESS:
      return { ...state, fetchSubscriptionPackInProgress: false, subscriptionPack: payload };
    case FETCH_SUBSCRIPTION_PACK_ERROR:
      return {
        ...state,
        fetchSubscriptionPackInProgress: false,
        subscriptionPackFetchError: payload,
      };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const setupIntentRequest = () => ({ type: SETUP_INTENT_REQUEST });
export const setupIntentSuccess = () => ({ type: SETUP_INTENT_SUCCESS });
export const setupIntentError = e => ({
  type: SETUP_INTENT_ERROR,
  error: true,
  payload: e,
});

export const fetchStripeSubscriptionRequest = () => ({ type: FETCH_SUBSCRIPTION_PACK_REQUEST });
export const fetchStripeSubscriptionSuccess = currentSubscription => ({
  type: FETCH_SUBSCRIPTION_PACK_SUCCESS,
  payload: currentSubscription,
});
export const fetchStripeSubscriptionError = e => ({
  type: FETCH_SUBSCRIPTION_PACK_ERROR,
  error: true,
  payload: e,
});

export const stripeCustomerRequest = () => ({ type: STRIPE_CUSTOMER_REQUEST });
export const stripeCustomerSuccess = () => ({ type: STRIPE_CUSTOMER_SUCCESS });
export const stripeCustomerError = e => ({
  type: STRIPE_CUSTOMER_ERROR,
  error: true,
  payload: e,
});

/* ================ Thunks ================ */

export const createStripeSetupIntent = () => (dispatch, getState, sdk) => {
  dispatch(setupIntentRequest());
  return sdk.stripeSetupIntents
    .create()
    .then(response => {
      const setupIntent = response.data.data;
      dispatch(setupIntentSuccess());
      return setupIntent;
    })
    .catch(e => {
      const error = storableError(e);
      log.error(error, 'create-setup-intent-failed');
      dispatch(setupIntentError(error));
      return { createStripeSetupIntentSuccess: false };
    });
};

// StripeCustomer is a relationship to currentUser
// We need to fetch currentUser with correct params to include relationship
export const stripeCustomer = () => dispatch => {
  dispatch(stripeCustomerRequest());

  return dispatch(fetchCurrentUser({ include: ['stripeCustomer.defaultPaymentMethod'] }))
    .then(() => {
      dispatch(stripeCustomerSuccess());
    })
    .catch(e => {
      dispatch(stripeCustomerError(storableError(e)));
    });
};

// Get subscription plan from API
export const subscriptionPack = params => dispatch => {
  dispatch(fetchStripeSubscriptionRequest());
  return fetchSubscriptionPack(params)
    .then(response => {
      const subscriptionPack = response?.data;
      dispatch(fetchStripeSubscriptionSuccess(subscriptionPack));
    })
    .catch(e => {
      dispatch(fetchStripeSubscriptionError(storableError(e)));
      log.error(e, 'fetch-stripe-subscription-failed');
      throw e;
    });
};
