import * as React from "react"
const IconMoney = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={800}
    height={800}
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M22 4H2a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h20a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1Zm-1 12.184A2.987 2.987 0 0 0 19.184 18H4.816A2.987 2.987 0 0 0 3 16.184V7.816A2.987 2.987 0 0 0 4.816 6h14.368A2.987 2.987 0 0 0 21 7.816ZM12 8a4 4 0 1 0 4 4 4 4 0 0 0-4-4Zm0 6a2 2 0 1 1 2-2 2 2 0 0 1-2 2Zm-5-2a1 1 0 0 1-1 1H5a1 1 0 0 1 0-2h1a1 1 0 0 1 1 1Zm13 0a1 1 0 0 1-1 1h-1a1 1 0 0 1 0-2h1a1 1 0 0 1 1 1Z" />
  </svg>
)
export default IconMoney
