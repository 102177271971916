import React from 'react';
import { string, func, bool, shape } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { propTypes } from '../../util/types';
import { ensureCurrentUser, ensureListing, ensureUser, getListingSummary } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { withViewport } from '../../util/uiHelpers';
import { FormattedMessage } from 'react-intl';

import { ContactButton, ListingAddress, NamedLink } from '../../components';

import css from './ListingCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;
const MOBILE_MAX_SIZE = 768;

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    listing,
    openContactModal,
    currentUser,
    history,
    setActiveListing,
    viewport,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', publicData } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const isMobileLayout = viewport.width < MOBILE_MAX_SIZE;

  const {
    metadata: { isVerified } = {},
    privateData: { certificationDocuments = [] } = {},
  } = ensuredCurrentUser.attributes.profile;

  const isCurrentUser =
    author.id && ensuredCurrentUser.id && author.id.uuid === ensuredCurrentUser.id.uuid;
  const adminNotVerifiedYet = certificationDocuments.length > 0 && !isVerified;
  const disabledButton = isCurrentUser || adminNotVerifiedYet;

  const listingSummary = getListingSummary(currentListing, config.listing);

  const handleClickListingCard = () => {
    setActiveListing(currentListing.id);
  }

  const listingCardProps = { className: classes };
  const listingCardContent = <div className={css.info}>
    <div className={css.mainInfo}>
      <div className={css.header}>
        <div className={css.title}>
          {richText(title, {
            longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
            longWordClass: css.longWord,
          })}
        </div>
        {(!isMobileLayout && !!setActiveListing) &&
          <NamedLink className={css.viewDetailsLink} name="ListingPage" params={{ id, slug }}>
            <FormattedMessage id="ListingCard.viewDetailsLink" />
          </NamedLink>}
      </div>
      <div className={css.listingSummary}>{listingSummary}</div>
      <ListingAddress className={css.address} location={publicData?.location} />
    </div>
  </div>

  return isMobileLayout || !setActiveListing ?
    <NamedLink {...listingCardProps} name="ListingPage" params={{ id, slug }}>{listingCardContent}</NamedLink> :
    <div {...listingCardProps} onClick={handleClickListingCard}>{listingCardContent}</div>
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,
  currentUser: propTypes.currentUser,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,

  history: shape({
    push: func.isRequired,
  }),
};

export default withViewport(ListingCardComponent);
