import React from 'react';
import { func, number } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { H2 } from '../Heading/Heading';
import NamedLink from '../NamedLink/NamedLink';
import { Button } from '..';
import css from './ContactModal.module.css';

const MoreCreditsNeededContent = ({ currentCredits, requiredCredits, onSaveCurrentState }) => (
  <>
    <H2 className={css.title}>
      <FormattedMessage id="ContactModal.moreCreditsNeeded.title" />
    </H2>
    <p className={css.description}>
      <FormattedMessage
        id="ContactModal.moreCreditsNeeded.description"
        values={{
          strong: chunks => <strong>{chunks}</strong>,
          em: chunks => <em>{chunks}</em>,
          currentCredits,
          requiredCredits,
          break: <><br /><br /></>,
        }}
      />
    </p>
    <NamedLink name="SubscriptionPage" className={css.button}>
      <Button onClick={onSaveCurrentState}>
        <FormattedMessage id="ContactModal.moreCreditsNeeded.primaryButton" />
      </Button>
    </NamedLink>
  </>
);

MoreCreditsNeededContent.propTypes = {
  currentCredits: number.isRequired,
  requiredCredits: number.isRequired,
  onSaveCurrentState: func.isRequired,
};

export default MoreCreditsNeededContent;
